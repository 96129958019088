import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './auth/auth.guard';
import { LoginComponent } from './pages/login/login.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { TasksComponent } from './pages/tasks/tasks.component';
import { TaskDetailComponent } from './pages/task-detail/task-detail.component';
import { MaintenanceComponent } from "./pages/maintenance/maintenance.component";
import { CheckinoutComponent } from './pages/checkinout/checkinout.component';
import { RefuellingComponent } from './pages/refuelling/refuelling.component';
import { CardsComponent } from './pages/cards/cards.component';
import { HistoryComponent } from './pages/history/history.component';
import { LinksComponent } from './pages/links/links.component';

const routes: Routes = [
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'tasks', component: TasksComponent, canActivate: [AuthGuard] },
  { path: 'tasks/:id', component: TasksComponent, canActivate: [AuthGuard] },
  { path: 'tasks/:id/:docEntry/:carCode/:trailerCode/:baseType/:deliveryType/:type', component: TaskDetailComponent, canActivate: [AuthGuard] },
  { path: 'tasks/:id/:docEntry/:carCode/:trailerCode/:baseType/:deliveryType/:type/:loadUnload', component: TaskDetailComponent, canActivate: [AuthGuard] },
  { path: 'tasks/:id/:docEntry/:carCode/:trailerCode/:baseType/:deliveryType/:type/:loadUnload/:lineCounter', component: TaskDetailComponent, canActivate: [AuthGuard] },
  { path: 'maintenance', component: MaintenanceComponent, canActivate: [AuthGuard] },
  { path: 'checkinout', component: CheckinoutComponent, canActivate: [AuthGuard] },
  { path: 'refuelling', component: RefuellingComponent, canActivate: [AuthGuard] },
  { path: 'cards', component: CardsComponent, canActivate: [AuthGuard] },
  { path: 'history', component: HistoryComponent, canActivate: [AuthGuard] },
  { path: 'links', component: LinksComponent, canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent },
  { path: '**', redirectTo: '/dashboard'}
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
