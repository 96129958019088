<ui5-page id="page" background-design="List">
  <header class="detail-page-header">
    <div class="detail-page-header-bar" style="height: 7rem;">
      <ui5-title class="title content-left">{{'menu.tasks' | translate}}({{rows?.length}})
        <span class="sub-title">{{process?.DocNum}} - {{process?.U_CarPlate}} {{(process?.Reboque) ? ' / ' + process?.Reboque : ''}}</span><br>
        <span class="sub-title">
         {{'tasks.details' | translate}}: <!-- {{carPlate}} --->{{rows[0]?.WhsName}} - {{rows[0]?.Type}} <br>
         {{'tasks.responsible' | translate}}: {{rows[0]?.Responsavel}}
          <span *ngIf="rows[0]?.Passage"><br>Ferry: {{rows[0]?.Passage}} - {{rows[0]?.PassDate}} {{rows[0]?.PassHour}}</span>
        </span>
      </ui5-title>
      <ui5-button
        design="Transparent"
        icon="synchronize"
        class="action-button" (click)="rerender()"></ui5-button>
    </div>
  </header>
  <div class="details-page">
    <div class="toolbar">
      <div class="btns-container">
        <ui5-button icon="activity-items" (click)="getProcesses(true,false)" style="margin-right:5px;" icon-end>{{'tasks.processes' | translate}}</ui5-button>
        <ui5-button *ngIf="process" icon="shipping-status" (click)="getTrailers()" style="margin-right:5px;" icon-end>{{'checkinout.trailer' | translate}}</ui5-button>
        <!--<ui5-button #downloadBtn icon="print" (click)="printMaps()" tooltip="Mapas"></ui5-button>-->
        <ui5-split-button #downloadBtn icon="print" tooltip="Mapas" style="float: right;"></ui5-split-button>
      </div>
      <ui5-input #searchInput class="searchInput" (keyup)="updateFilter($event)" placeholder="{{'general.search' | translate}}..." >
        <ui5-icon id="searchIcon" slot="icon" name="search"></ui5-icon>
      </ui5-input>
    </div>

    <ngx-datatable
      #datatable
      style="width: 100%"
      class="material"
      [rows]="rows"
      [columnMode]="ColumnMode.force"
      [headerHeight]="50"
      [footerHeight]="50"
      rowHeight="auto"
      [limit]="20"
      [messages]="{emptyMessage: 'general.emptymessage' | translate,
        totalMessage: 'general.totalmessage' | translate,
        selectedMessage: 'general.selectedmessage' | translate}"
      (activate)="onActivate($event)"
      (select)="onSelect($event)">

      <!-- Row Detail Template -->
      <ngx-datatable-row-detail #myDetailRow (toggle)="onDetailToggle($event)">
        <ng-template let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>
          <div class="mobile-row" (click)="onSelectRow(row)">
            <div class="row-line-container">
              <ui5-label class="row-title">{{'tasks.address' | translate}}:</ui5-label>
              <span class="row-line">{{ row['LocalAddress'] }}</span>
              <ui5-label class="row-title">{{'tasks.date' | translate}}:</ui5-label>
              <span class="row-line">{{ row['PrevDate'] }}</span>
              <ui5-label class="row-title">{{'tasks.type' | translate}}:</ui5-label>
              <span class="row-line">{{ row['DeliveryType'] }}</span>
              <ui5-label class="row-title">{{'tasks.destination' | translate}}:</ui5-label>
              <span>{{row['WhsName']}}</span><br>
              <ui5-badge *ngIf="row['RecEnt'] == 'Entrega'" color-scheme="8">
                {{row['RecEnt']}}
              </ui5-badge>
              <ui5-badge *ngIf="row['RecEnt'] != 'Entrega'" color-scheme="1">
                {{row['RecEnt']}}
              </ui5-badge>
              <br>
              <br>
              <ui5-label class="row-title">{{'tasks.status' | translate}}:</ui5-label>
              <ui5-button *ngIf="row['Status'] == 'End'" class="" design="Transparent" icon="alert"
                          class="green-text" style="margin:-10px;opacity: 1;" disabled>{{'tasks.finalized' | translate}}</ui5-button>
              <ui5-button *ngIf="row['Status'] != 'End'" design="Transparent"
                          style="margin:-10px;opacity: 1;" icon="away" disabled>{{'tasks.inprogress' | translate}}</ui5-button>
              <br>
              <span *ngIf="row['U_Priority'] === 'FIX'" class="red-text" style="">{{row['U_Priority']}}</span>
              <br> <br>
            </div>
          </div>
        </ng-template>
      </ngx-datatable-row-detail>

      <!--<ngx-datatable-column
        [width]="50"
        [sortable]="false"
        [canAutoResize]="false"
        [draggable]="false"
        [resizeable]="false">
        <ng-template
          ngx-datatable-header-template
          let-value="value"
          let-allRowsSelected="allRowsSelected"
          let-selectFn="selectFn">
          <ui5-checkbox #allRowsCheck class="select-all pointer" [checked]="allRowsSelected"
                        (change)="selectFn(!allRowsSelected)"></ui5-checkbox>
        </ng-template>
        <ng-template
          ngx-datatable-cell-template
          let-value="value"
          let-isSelected="isSelected"
          let-onCheckboxChangeFn="onCheckboxChangeFn">
          <ui5-checkbox [checked]="isSelected" (click)="$event.stopPropagation()"
                        (change)="onCheckboxChangeFn($event)"></ui5-checkbox>
        </ng-template>
      </ngx-datatable-column>-->
      <ngx-datatable-column prop="Name" [flexGrow]="3">
        <ng-template let-row="row" let-column="column" let-sort="sortFn" ngx-datatable-header-template>
          <span class="pointer" (click)="sort()">{{'tasks.name' | translate}}</span>
        </ng-template>
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          <b>{{value}}</b>
          <span class="row-id">
            {{ row['needCarga'] == 'Y' ? ('dashboard.load' | translate) :  ('dashboard.unload' | translate) }}
          </span>
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column prop="LocalAddress" [flexGrow]="1">
        <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
          <span class="pointer mobile-hidden" (click)="sort()">{{'tasks.address' | translate}}</span>
        </ng-template>
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          <span class="mobile-hidden">{{ value }}</span>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column prop="PrevDate" [flexGrow]="1">
        <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
          <span class="pointer mobile-hidden" (click)="sort()">{{'tasks.date' | translate}}</span>
        </ng-template>
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          <span class="mobile-hidden">{{ value }}</span>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column prop="DeliveryType" [flexGrow]="1">
        <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
          <span class="pointer mobile-hidden" (click)="sort()">{{'tasks.type' | translate}}</span>
        </ng-template>
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          <span class="mobile-hidden">{{ value }}</span>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column prop="RecEnt" [flexGrow]="1">
        <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
          <span class="pointer mobile-hidden" (click)="sort()">{{'tasks.destination' | translate}}</span>
        </ng-template>z
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          <div class="mobile-hidden">
            <span>{{row['WhsName']}}</span><br>
            <ui5-badge *ngIf="value == 'Entrega'" color-scheme="8">
              {{value}}
            </ui5-badge>
            <ui5-badge *ngIf="value != 'Entrega'" color-scheme="1">
              {{value}}
            </ui5-badge>
          </div>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column prop="Status"  [flexGrow]="1">
        <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
          <span class="pointer mobile-hidden" (click)="sort()">{{'tasks.status' | translate}}</span>
        </ng-template>
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          <div class="mobile-hidden">
            <ui5-button *ngIf="value == 'End'" class="" design="Transparent" icon="alert"
                        class="green-text" style="margin:-10px;opacity: 1;" disabled>{{'tasks.finalized' | translate}}</ui5-button>
            <ui5-button *ngIf="value != 'End'" design="Transparent"
                        style="margin:-10px;opacity: 1;" icon="away" disabled>{{'tasks.inprogress' | translate}}</ui5-button>
            <br>
            <span *ngIf="row['U_Priority'] === 'FIX'" class="red-text" style="">{{row['U_Priority']}}</span>
          </div>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column prop="CountIncidence" [maxWidth]="55" [flexGrow]="1">
        <ng-template let-column="column" let-sort="sortFn" ngx-datatable-header-template>
          <span class="pointer" (click)="sort()">Incid.</span>
        </ng-template>
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          <ui5-button *ngIf="value > 0" icon="incident" class="red-text" style="opacity: 1;"
                      design="Transparent" aria-labelledby="lblCancel" disabled></ui5-button>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="" [sortable]="false" [maxWidth]="70" >
        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          <div class="mobile-hidden">
          <ui5-button class="nav-btn" icon="navigation-right-arrow" design="Transparent"
                      aria-labelledby="lblNavigationRightArrow"></ui5-button>
          </div>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
  </div>

  <ui5-dialog #dialogProcesses class="dialog-process" headerText="{{'tasks.processlist' | translate}} - {{carPlate}} {{ trailerPlate ? '/ ' + trailerPlate: ''}}">
    <div class="dialog-content">
      <ui5-input class="searchInput full-width" [(ngModel)]="searchProcess"
                 placeholder="{{'general.search' | translate}}..." ngDefaultControl oragami>
        <ui5-icon id="searchIcon" slot="icon" name="search"></ui5-icon>
      </ui5-input>
      <ui5-list id="myList" class="full-width">
        <ui5-li *ngFor="let p of processes | filter:searchProcess" [description]="'Data: '+ p?.LoadDate + ' - ' + p?.UnloadDate"
                [additionalText]="p?.Type + ' - ' + p?.U_DeliveryType" additional-text-state="Information"
                [class]="(p?.Finish > 0) ? 'success-list' : ( p?.CheckStatus  > 0 ? 'warning-list' : '')"
                (click)="selectProcess(p)">{{p?.DocNum}} - {{p?.U_Vehicle}} {{(p?.Reboque) ? ' / ' + p?.Reboque : ''}}</ui5-li>
      </ui5-list>
    </div>
    <div slot="footer" class="dialog-footer fixed">
      <ui5-button class="margin-btn" design="Transparent" (click)="closeDialogProcesses()">{{'general.cancel' | translate}}</ui5-button>
    </div>
  </ui5-dialog>

  <ui5-dialog #dialogTrailers class="dialog-trailers" headerText="{{'checkinout.trailer' | translate}} - {{process?.Reboque}}">
    <div class="dialog-content">
      <ui5-input class="searchInput full-width" [(ngModel)]="searchTrailer"
                 placeholder="{{'general.search' | translate}}..." ngDefaultControl oragami>
        <ui5-icon id="searchIcon" slot="icon" name="search"></ui5-icon>
      </ui5-input>
      <ui5-list id="myList" class="full-width">
        <ui5-li *ngFor="let t of trailers | filter:searchTrailer" [description]=""
                [additionalText]="" additional-text-state="Information"
                (click)="selectTrailer(t)">{{t?.U_CarPlate}}</ui5-li>
      </ui5-list>
    </div>
    <div slot="footer" class="dialog-footer fixed">
      <ui5-button class="margin-btn" design="Transparent" (click)="closeDialogTrailers()">{{'general.cancel' | translate}}</ui5-button>
    </div>
  </ui5-dialog>

  <ui5-dialog #dialogViewer header-text="{{'tasks.maps' | translate}}" stretch>
    <div class="dialog-content">
        <ngx-extended-pdf-viewer
          [base64Src]="filePdf?.src.split(',').pop()"
          backgroundColor="#E5E5E5"
          theme="dark"
          [filenameForDownload]="filePdf?.filename"
          [useBrowserLocale]="true"
          [handTool]="false"
          [height]="(getWidth() <= 960 ) ? '92%' : '87%'"
          [showSidebarButton]="false"
          [showHandToolButton]="false"
          [showPrintButton]="true"
          [showDownloadButton]="true"
          [showOpenFileButton] ="false"
          [showBookmarkButton] ="false"
          [showSecondaryToolbarButton] ="true"
          [delayFirstView]="1">
        </ngx-extended-pdf-viewer>
    </div>
    <div slot="footer" class="dialog-footer fixed">
      <ui5-button icon="undo" class="margin-btn" design="Transparent" (click)="closeDialogMaps()">{{'general.back' | translate}}</ui5-button>
    </div>
  </ui5-dialog>

  <!-- Trailer State  -->
  <ui5-dialog #dialogTrailerState class="dialog-trailers" headerText="{{'checkinout.trailer' | translate}} - {{process?.Reboque}}" >
    <div [formGroup]="formTrailerState" class="dialog-content">
      <div class="flex-column margin">
        <ui5-label class="block" for="LocalChange" required>{{'tasks.localchange' | translate}}:</ui5-label>
        <ui5-select #localchange placeholder=""
                    class="select" class="full-width"
                    (change)="formTrailerState.controls['LocalChange'].setValue($event['detail']['selectedOption']['value']);"
                    formControlName="LocalChange" ngDefaultControl required oragami>
          <ui5-option *ngFor="let l of localChange" [value]="l.Code"
                      [selected]="l.Code == formTrailerState.controls['LocalChange']['value']">{{l.Name}}</ui5-option>
        </ui5-select>
      </div>
      <div class="flex-column margin" *ngIf="formTrailerState.controls['LocalChange']['value'] == 'NOT'">
        <ui5-label class="block" for="NotherLocal" required>{{'tasks.local' | translate}}:</ui5-label>
        <ui5-input placeholder="" class="full-width" formControlName="NotherLocal"
                   ngDefaultControl oragami></ui5-input>
      </div>
      <div class="flex-column margin">
        <ui5-label class="block" for="Strap" required>{{'tasks.strap' | translate}}:</ui5-label>
        <ui5-input #strap type="Number" placeholder="" class="full-width" formControlName="Strap"
                   ngDefaultControl oragami></ui5-input>
      </div>
      <div class="flex-column margin">
        <ui5-label class="block" for="Rulers" required>{{'tasks.rulers' | translate}}:</ui5-label>
        <ui5-input #rulers type="Number" placeholder="" class="full-width" formControlName="Rulers"
                   ngDefaultControl oragami></ui5-input>
      </div>

      <div class="flex-column ">
        <ui5-checkbox style="margin-left: -10px;" formControlName="Canvas"
                      (change)="formTrailerState.controls['Canvas'].setValue($event['target']['checked'])"
                      [checked]="formTrailerState.controls['Canvas']['value']"
                       text="{{'tasks.canvas' | translate}}" ngDefaultControl oragami></ui5-checkbox>
      </div>

      <div class="flex-column ">
        <ui5-checkbox style="margin-left: -10px;" formControlName="Tyres"
                      (change)="formTrailerState.controls['Tyres'].setValue($event['target']['checked'])"
                      [checked]="formTrailerState.controls['Tyres']['value']"
                      ngDefaultControl text="{{'tasks.tyres' | translate}}" oragami></ui5-checkbox>
      </div>

      <div class="flex-column ">
        <ui5-checkbox style="margin-left: -10px;" formControlName="Trailer"
                      (change)="formTrailerState.controls['Trailer'].setValue($event['target']['checked'])"
                      [checked]="formTrailerState.controls['Trailer']['value']"
                      ngDefaultControl text="{{'tasks.trailer' | translate}}" oragami></ui5-checkbox>
      </div>

      <div class="flex-column margin">
        <ui5-label class="block" for="Observations">{{'tasks.observations' | translate}}:</ui5-label>
        <ui5-textarea rows="5" placeholder="" class="full-width" formControlName="Observations"
                      ngDefaultControl oragami></ui5-textarea>
       </div>
    </div>
    <div slot="footer" class="dialog-footer fixed">
      <div>
        <ui5-button icon="undo" class="margin-btn" design="Transparent" (click)="closeDialogTrailerState()">{{'general.back' | translate}}</ui5-button>
        <ui5-button design="Emphasized" (click)="saveTrailerState()">{{'general.confirm' | translate}}</ui5-button>
      </div>
    </div>
  </ui5-dialog>

  <ui5-popover #popoverMaps id="popoverMaps" placement-type="Bottom">
    <!--<div class="popover-header">
      <ui5-title style="padding: 0.25rem 1rem 0rem 1rem"></ui5-title>
    </div>-->
    <div class="popover-content">
      <ui5-list separators="None" >
        <ui5-li icon="print" (click)="printMaps()">{{ 'tasks.driver' | translate}}</ui5-li>
        <ui5-li type="{{(process?.Type == 'NAC') ? 'Active' : 'Inactive'}}" icon="map-2" class="{{(process?.Type == 'NAC') ? 'Active' : 'Inactive'}}"
                (click)="(process?.Type == 'NAC') ? printMaps('loadunload') : false;">
          {{ 'tasks.delpick' | translate}}</ui5-li>
      </ui5-list>
    </div>
  </ui5-popover>

</ui5-page>
