import {Component, ElementRef, OnInit, AfterViewInit, ViewChild} from '@angular/core';

import {LoadingComponent} from '../../shared/dialogs/loading/loading.component';
import {NotificationComponent} from '../../shared/dialogs/notification/notification.component';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ApiService} from '../../services/api.service';
import {AlertService} from '../../services/alert.service';
import {TranslateService} from '@ngx-translate/core';
import {forkJoin} from 'rxjs';
import * as moment from 'moment';
import {Router} from '@angular/router';


@Component({
  selector: 'app-refuelling',
  templateUrl: './refuelling.component.html',
  styleUrls: [ './refuelling.component.scss' ],
})
export class RefuellingComponent implements OnInit, AfterViewInit {
  @ViewChild('dialogFiles') dialogFilesEl: ElementRef;
  @ViewChild('dialogHistory') dialogHistoryEl: ElementRef;
  @ViewChild('dialogPreview') dialogPreviewEl: ElementRef;
  @ViewChild('pump') pumpEl: ElementRef;
  @ViewChild('odometer') odometerEl: ElementRef;
  @ViewChild('liters') litersEl: ElementRef;
  @ViewChild('ticket') ticketEl: ElementRef;
  @ViewChild('ticketCollection') ticketCollectionEl: ElementRef;
  @ViewChild('ticketFile') ticketFileEl: ElementRef;
  pumps = [];
  history = [];
  files = [];
  driver: any;
  formRefuelling: FormGroup;
  isPdf: boolean;
  fileViewer: any;


  constructor(private fb: FormBuilder,
              private router: Router,
              private loading: LoadingComponent,
              private notification: NotificationComponent,
              private apiService: ApiService,
              private alertDialog: AlertService,
              private translate: TranslateService) { }

  ngOnInit(): void {
    this.formRefuelling = this.fb.group({
      U_Bomb: ['', Validators.required],
      BombAddress: [''],
      Suplier: [''],
      SuplierCode: [''],
      Odometer: ['',Validators.required],
      U_Liters: ['0', [Validators.required, Validators.min(1)]],
      U_Liters_2: ['0'],
      U_NrTalon: ['']
    });
    this.checkLogin();
  }

  ngAfterViewInit(): void {
    const self = this;
    this.ticketFileEl.nativeElement.addEventListener("ui5-change", event => {
      const files = event.target.files;
      if (!files.length) {
        console.log('No files')
      } else {
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          //self.uploadFile(file, file.name, file.size, file.type, 'TEMP', 'NOX_ODCO');
          const reader = new FileReader();
          reader.readAsArrayBuffer(file);
          reader.onload = function (event) {
            self.uploadFile(reader.result, file.name, file.size, file.type, 'TEMP_'+localStorage.getItem('userid'), 'NOX_ODCO')
          };
          reader.onerror = function (error) {
            console.log('Erro ao ler a imagem : ', error);
            self.notification.show('Error','Erro ao ler a imagem : '+ JSON.stringify(error));
          };
        }
      }
    });

    this.ticketCollectionEl.nativeElement.addEventListener("drop", event => {
      event.preventDefault();
      const files = event.dataTransfer.files;
      // Take the files from the drop event and create <ui5-upload-collection-item> from them
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        //console.log(file.size);
        //self.uploadFile(file, file.name, file.size, file.type, 'TEMP', 'NOX_ODCO');
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onload = function (event) {
          self.uploadFile(reader.result, file.name, file.size, file.type, 'TEMP_'+localStorage.getItem('userid'), 'NOX_ODCO')
        };
        reader.onerror = function (error) {
          console.log('Erro ao ler a imagem : ', error);
          self.notification.show('Error','Erro ao ler a imagem : '+ JSON.stringify(error));
        };
      }
    });

    this.ticketCollectionEl.nativeElement.addEventListener("item-delete", event => {
      if(event && event.detail && event.detail.item) {
        this.deleteFile(event.detail.item.title);
      } else {
        this.notification.show('Error','Erro a apagar ficheiro');
      }
    });
  }

  stationChange(station): void {
    const obj = this.pumps.find(p => p.Code == station);
    if(obj) {
      this.formRefuelling.patchValue({
        BombAddress: obj['U_Address'],
        Suplier: obj['Suplier'],
        SuplierCode: obj['SuplierCode']
      });
    }
  }

  openFiles(): void {
    this.loading.show();
    this.apiService.getFiles('TEMP_'+localStorage.getItem('userid'),'NOX_ODCO')
      .subscribe(data => {
        // @ts-ignore
        this.files = data
        this.dialogFilesEl.nativeElement.show();
      },error => {
        this.loading.hide();
        this.notification.show('Error',JSON.stringify(error['error']));
        console.log(error.error);
      },() => {
        this.loading.hide();
      });
  }

  closeFiles(): void {
    this.dialogFilesEl.nativeElement.close();
  }

  downloadFile(event,url,filename): void {
    //console.log(event);
    let preview = event?.srcElement.classList.contains('preview-link');
    if(event?.isMarked == 'link' && !preview) {
      this.apiService.downloadFile(url,filename);
    }
  }

  previewFile(event,url,filename): void {
    let preview = event?.srcElement.classList.contains('preview-link');
    if(event?.isMarked == 'link' && preview) {
      const cleanURL = this.apiService.getFileURL(url);
      const ext = filename.substring(filename.lastIndexOf('.') + 1);
      this.isPdf = ext == 'pdf';
      this.fileViewer = {
        src: cleanURL,
        name: filename
      }
      this.dialogPreviewEl.nativeElement.show();
    }
  }

  closeDialogPreview(): void {
    this.dialogPreviewEl.nativeElement.close();
  }

  getWidth(): number {
    return window.innerWidth;
  }

  openHistory(): void {
    this.loading.show();
    this.apiService.getRefuelHistory(this.driver['VehicleCode'].toString())
      .subscribe(data => {
        // @ts-ignore
        this.history = data
        this.dialogHistoryEl.nativeElement.show();
      },error => {
        this.loading.hide();
        this.notification.show('Error',JSON.stringify(error['error']));
        console.log(error.error);
      },() => {
        this.loading.hide();
      });

  }

  closeHistory(): void {
    this.dialogHistoryEl.nativeElement.close();
  }

  submit(): void {
    const controls = this.formRefuelling.controls;
    const U_Bomb = this.pumpEl.nativeElement;
    const Odometer = this.odometerEl.nativeElement;
    const U_Liters = this.litersEl.nativeElement;
    U_Bomb.setAttribute('value-state', '');
    Odometer.setAttribute('value-state', '');
    U_Liters.setAttribute('value-state', '');
    /** check form */
    if (this.formRefuelling.invalid) {
      Object.keys(controls).forEach(controlName => {
        controls[controlName].markAsTouched()
        if (controls[controlName].invalid) {
          try {eval(controlName).setAttribute('value-state', 'Error')} catch (e) {console.log(e)}
        }
      });
      return;
    }

    const formData = {
      BombAddress: controls['BombAddress'].value,
      Date: moment().format('YYYY-MM-DD'),
      Hour: moment().format('HHmm'),
      Odometer: controls['Odometer'].value,
      Suplier: controls['Suplier'].value,
      SuplierCode: controls['SuplierCode'].value,
      ThumbnailDriver: this.driver['ThumbnailDriver'],
      U_Bomb: controls['U_Bomb'].value,
      U_Date: moment().format('YYYY-MM-DD'),
      U_Hour: moment().format('HH:mm'),
      VehicleCode: this.driver['VehicleCode'],
      VehiclePlate:  this.driver['VehiclePlate'],
      user:  this.driver['user'],
      userCode: this.driver['userCode']
    }
    const fuels = [
      {
        U_Liters: controls['U_Liters'].value,
        U_TypeComb: "Gasóleo",
        key: "GAS"
      },
      {
        U_Liters: controls['U_Liters_2'].value,
        U_TypeComb: "AdBlue",
        key: "ADB"
      }
    ];

    if(fuels[0]['U_Liters'] < 1) {
      this.notification.show('Error','Quantidade de combustível tem que ser maior que 0');
      return;
    }
    if(this.files.length == 0) {
      this.notification.show('Error','Talão é obrigatório');
      return;
    }

    this.loading.show();
    this.apiService.saveFuel(formData, fuels, this.files).subscribe(data => {
      console.log(data);
      this.notification.show('Success','Submetido com sucesso');
      this.formRefuelling.reset();
      this.formRefuelling.patchValue({
        U_Liters: 0,
        U_Liters_2: 0
      });
      this.files = []
      this.getDriverDetails();
      this.getHistory();
    },error => {
      this.loading.hide();
      this.notification.show('Error',JSON.stringify(error['error']));
      console.log(error.error);
    },() => {
      this.loading.hide();
    });

  }

  checkLogin(): void {
    this.loading.show();
    this.apiService.checkLogin(moment().format('YYYY-MM-DD'))
      .subscribe(data => {
        if(!data['U_Status'] || data['U_Status'] == "CHECKOUT") {
          this.router.navigate(['/']);
          this.notification.show('Info','Efectue o Checkin!');
        } else {
          setTimeout(() => {
            this.getDriverDetails();
            this.updateFilesTemp('TEMP_'+localStorage.getItem('userid'),null,'NOX_ODCO');
            this.getDetails();
          },100);
        }
      },error => {
        this.loading.hide();
        this.notification.show('Error',JSON.stringify(error['error']));
        console.log(error.error);
      },() => {
        this.loading.hide();
      });
  }

  getDetails(): void {
    this.loading.show();
    forkJoin([
      this.apiService.getDriver(),
      this.apiService.getStations()
    ])
      .subscribe(data => {
        this.driver = data[0];
        // @ts-ignore
        this.pumps = data[1];
        this.pumps.unshift({ Code: '', Name: ''});
      },error => {
        this.loading.hide();
        this.notification.show('Error',JSON.stringify(error['error']));
        console.log(error.error);
      },() => {
        this.loading.hide();
      });
  }

  getDriverDetails(): void {
    this.loading.show();
    this.apiService.getDriverDetails()
      .subscribe(data => {
        if(data) {
          this.formRefuelling.patchValue({
            Odometer: data['Kms']
          });
        }
      },error => {
        this.loading.hide();
        this.notification.show('Error',JSON.stringify(error['error']));
        console.log(error.error);
      },() => {
        this.loading.hide();
      });
  }

  getHistory() {
    this.loading.show();
    this.apiService.getRefuelHistory(this.driver['VehicleCode'].toString())
      .subscribe(data => {
        // @ts-ignore
        this.history = data
      },error => {
        this.loading.hide();
        this.notification.show('Error',JSON.stringify(error['error']));
        console.log(error.error);
      },() => {
        this.loading.hide();
      });
  }

  getFiles(): void {
    setTimeout(() => {
      this.loading.show();
      this.apiService.getFiles('TEMP_'+localStorage.getItem('userid'),'NOX_ODCO')
        .subscribe(data => {
         // @ts-ignore
          this.files = data;
        },error => {
          this.loading.hide();
          this.notification.show('Error',JSON.stringify(error['error']));
          console.log(error.error);
        },() => {
          this.loading.hide();
        });
    },100);
  }

  uploadFile(file, name, size, type, objcode, objtype): void {
    this.loading.show();
    this.apiService.uploadFile(file, name, size, type, objcode, objtype)
      .subscribe(data => {
        this.getFiles();
      },error => {
        this.loading.hide();
        this.notification.show('Error',JSON.stringify(error['error']));
        console.log(error.error);
      },() => {
        this.loading.hide();
      });
  }

  updateFilesTemp(id, code, type): void {
    this.loading.show();
    this.apiService.updateFilesTemp(id, code, type)
      .subscribe(data => {
        console.log(data);
      },error => {
        this.loading.hide();
        this.notification.show('Error',JSON.stringify(error['error']));
        console.log(error.error);
      },() => {
        this.loading.hide();
      });
  }

  deleteFile(id): void {
    const self = this;
    //self.alertDialog.confirm(self.translate.instant('general.confirmdelete'),function(){
      self.loading.show();
      self.apiService.deleteFile(id)
        .subscribe(data => {
          self.getFiles();
        },error => {
          self.loading.hide();
          self.notification.show('Error',JSON.stringify(error['error']));
          console.log(error.error);
        },() => {
          self.loading.hide();
        });
    //},function(){});
  }

  clearFiles(event): void {
    try {
      if(event.target?.files.length > 0) {
        const input = event.target?.shadowRoot.querySelector('input');
        if(input) {
          input.value = null;
        }
      }
    } catch (e) {
      //console.log(e);
    }
  }

}
