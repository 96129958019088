import {AfterViewInit, Component, ElementRef, HostListener, Inject, OnInit, ViewChild} from '@angular/core';
import {NavigationEnd, NavigationStart, Router} from '@angular/router';
import {LocationStrategy} from '@angular/common';
import {Observable} from 'rxjs';
import {ChatService, ChatServiceToken, UnreadMessageCountPlugin} from '@pazznetwork/ngx-chat';
import { addCustomCSS } from "@ui5/webcomponents-base/dist/Theming";
import { setLanguage } from '@ui5/webcomponents-base/dist/config/Language';

import { AuthService } from '../../auth/auth.service';
import { TranslateService } from '@ngx-translate/core';
import {AlertService} from '../../services/alert.service';
import { getTheme, setTheme } from '@ui5/webcomponents-base/dist/config/Theme';
import { LoadingComponent } from '../dialogs/loading/loading.component';
import { NotificationComponent } from '../dialogs/notification/notification.component';
import {filter} from 'rxjs/operators';
addCustomCSS("ui5-shellbar",`
  .ui5-shellbar-copilot-wrapper {width: 44px;height: 44px;
      background: url("/assets/images/copilot.png");}
  .ui5-shellbar-copilot-wrapper:hover {width: 44px;height: 44px;
      background: url("/assets/images/copilot.png");}
  .ui5-shellbar-copilot-wrapper svg {display: none;}
  @media screen and (max-width: 576px) {
      ui5-button[icon="discussion"] {
      visibility: visible !important;
      display: inline-block;
      position: absolute;
      right: 95px;
      } }`)

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, AfterViewInit  {
  @HostListener('document:click', ['$event'])
  clickout(event) {
    if(this.chatState()) {
      const chatList = <HTMLInputElement>document.querySelector('.roster-list');
      const drawer = <HTMLInputElement>document.querySelector('.roster-drawer');
      if ((chatList && chatList.contains(event.target)) || (drawer && drawer.contains(event.target))){
        //console.log('inside');
      } else{
        //console.log('outside');
        this.toogleChat();
      }
    }
  }
  @ViewChild('shellbar') shellbarEl: ElementRef;
  @ViewChild('popover') popoverEl: ElementRef;
  @ViewChild('language') languageEl: ElementRef;
  @ViewChild('connection') connectionEl: ElementRef;
  @ViewChild('chatBtn') chatBtnEl: ElementRef;
  @ViewChild('wcNotification') notificationEl: ElementRef;
  @ViewChild('popoverLang') popoverLangEl: ElementRef;
  @ViewChild('menu') menuEl: ElementRef;
  @ViewChild('wcConnectionOn') wcConnectionOnEl: ElementRef;
  @ViewChild('wcConnectionOff') wcConnectionOffEl: ElementRef;

  isLoggedIn$: Observable<boolean>;
  unreadMessageCountPlugin: UnreadMessageCountPlugin;
  user: any;

  constructor(private router: Router,
              private authService: AuthService,
              private translate: TranslateService,
              private loading: LoadingComponent,
              private alertDialog: AlertService,
              private notification: NotificationComponent,
              location: LocationStrategy,
              @Inject(ChatServiceToken) public chatService: ChatService) {

    this.router.events.pipe(filter(event => event instanceof NavigationStart)).subscribe(event => {
      this.notification.hide();
      this.loading.hide();
      this.alertDialog.hide();
    });

    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(event => {
      this.checkConnection(false);
        const menu = (<HTMLInputElement>document.querySelector('.sidebar'));
        if(menu) {
          const menuItems = menu.querySelectorAll('ui5-side-navigation-item');
          if (menuItems) menuItems.forEach((item) => {
            if (item.classList[0] != event['url']) item['selected'] = false;
          });
        }
    });
    /*location.onPopState(() => {
     const menu = (<HTMLInputElement>document.querySelector('.sidebar'));
     if(menu) {
       const menuItems = menu.querySelectorAll('ui5-side-navigation-item');
       if (menuItems) menuItems.forEach((item) => {
         //if(item.classList[0] != url)
         item['selected'] = false;
       });
     }
   });*/
    this.unreadMessageCountPlugin = this.chatService.getPlugin(UnreadMessageCountPlugin);
    this.unreadMessageCountPlugin.unreadMessageCountSum$.subscribe(async (count) => {
      if(window?.location?.pathname != '/login' && count > 0) {
        this.playNotification();
      }
    });
  }

  ngOnInit() {
    const login = localStorage.getItem('login');
    if (login === 'Y') {
      this.authService.setLogin();
    }
    this.isLoggedIn$ = this.authService.isLoggedIn;
    this.isLoggedIn$.subscribe((e) => {
      setTimeout(() => {
        this.user = localStorage.getItem('user');
      },1000);
    });
  }

  onLogout() {
    /** Fake Logout **/
    /*const self = this;
    self.loading.show();
    setTimeout(() => {
      self.popoverEl.nativeElement.close();
      self.authService.setLogout();
      localStorage.setItem('login', 'N');
      self.loading.hide();
      self.router.navigate(['/login'], {
        queryParams: {
          returnUrl: this.router.url
        }
      });
    }, 1000);*/
    /**  **/

    this.loading.show();
    this.authService.logout().subscribe(data => {
      this.popoverEl.nativeElement.close();
      this.authService.setLogout();
      localStorage.setItem('login', 'N');
      this.router.navigate(['/login'], {
        queryParams: {
          returnUrl: (this.router.url ? this.router.url.replace(/%23/g, "#") : '')
        }
      });
    },error => {
      this.notification.show('Error',error['error']);
      console.log(error.error);
    },() => {
      this.loading.hide();
    });
  }

  ngAfterViewInit(): void {
    const self = this;
    if (self.shellbarEl) {
      self.shellbarEl.nativeElement.addEventListener('profile-click', event =>
        self.popoverEl.nativeElement.showAt(event.detail.targetRef));
      self.shellbarEl.nativeElement.addEventListener('notifications-click', event =>
        self.notificationEl.nativeElement.show());
      self.languageEl.nativeElement.addEventListener('click', event =>
          self.popoverLangEl.nativeElement.showAt(event.detail.targetRef));
      self.connectionEl.nativeElement.addEventListener('click', event =>
        self.checkConnection(true));
      self.menuEl.nativeElement.addEventListener('click', event =>
        setTimeout(() => window.dispatchEvent(new Event('resize')),200));
      self.shellbarEl.nativeElement.addEventListener('menu-item-click', event => {
        if(event['detail']['item']) {
          const url = event['detail']['item'].classList[0];
          this.router.navigate([url.replace('menu-','/')]);
          const menu = (<HTMLInputElement>document.querySelector('.sidebar'));
          if(menu) {
            const menuItems = menu.querySelectorAll('ui5-side-navigation-item');
            if(menuItems) menuItems.forEach((item) => {
                if(item.classList[0] != url) item['selected'] = false;
            });
          }
        }
      });
      self.shellbarEl.nativeElement.addEventListener('product-switch-click', event => {
        const menu = (<HTMLInputElement>document.getElementById('menu'));
        if(menu) {
          menu.click();
        }
      });
      setTimeout(() => {
        // @ts-ignore
        const shadowShellEl = <HTMLInputElement>document.querySelector('ui5-shellbar').shadowRoot;
        const rightMenu = shadowShellEl.querySelector('ui5-button[title="Products"]');
        if(rightMenu) {
          //rightMenu.setAttribute('style', 'order:6 !important;visibility:visible !important;');
        }
      },500);

      if (self.chatBtnEl) {
        self.chatBtnEl.nativeElement.addEventListener('click', event => {
          self.toogleChat();
        });
      }
    }
  }

  toggleTheme(): void {
    const html = (<HTMLInputElement>document.documentElement);
    if (getTheme() === 'sap_fiori_3_dark') {
      setTheme('sap_fiori_3');
      localStorage.setItem('theme', 'sap_fiori_3');
      html.classList.add('theme-white');
    } else {
      setTheme('sap_fiori_3_dark');
      localStorage.setItem('theme', 'sap_fiori_3_dark');
      html.classList.remove('theme-white');
    }
  }

  toogleChat(): void {
    /*const chat = <HTMLInputElement>document.querySelector('ngx-chat');
    const style = window.getComputedStyle(chat);
    if (style.display === 'none') {
      chat.style.display = 'block';
    } else {
      chat.style.display = 'none';
    }*/
    if(this.popoverEl) this.popoverEl.nativeElement.close();
    const drawer = <HTMLInputElement>document.querySelector('.roster-drawer');
    if(drawer) drawer.click();
  }

  chatState(): boolean {
    const chat = <HTMLInputElement>document.querySelector('.roster-list');
    if(chat) {
      const style = window.getComputedStyle(chat);
      if (style.right === '0px') {
        return true
      }
    }
    return false;
  }

  playNotification(): void {
    const audio = new Audio();
    audio.src = '../../../assets/sounds/bubble.mp3';
    audio.load();
    audio.play();
  }

  changeLanguage(language: string): void {
    this.translate.use(language);
    localStorage.setItem('lang',language);
    if(language == 'pt') {
      setLanguage('pt_PT');
    } else {
      setLanguage(language);
    }
  }

  checkConnection(clicked): void {
    if(navigator.onLine){
      if(clicked) this.wcConnectionOnEl.nativeElement.show();
      this.connectionEl.nativeElement.icon = 'connected';
    } else {
      if(clicked) this.wcConnectionOffEl.nativeElement.show();
      this.connectionEl.nativeElement.icon = 'disconnected';
    }
  }

}
