<ui5-page id="page" class="page" background-design="Solid" floating-footer show-footer>
  <header class="detail-page-header">
    <div class="detail-page-header-bar" style="height: 7rem;">
      <ui5-title class="title content-left">
        <!--{{(task?.needCarga == 'Y' ? task?.LoadName : task?.UnloadName)}}-->
        <span class="sub-title">
          <!--{{'tasks.process' | translate}}: {{task?.DocNumProcess}} <br>-->
          <!--{{'tasks.date' | translate}}: {{(task?.needCarga == 'Y' ? task?.PrevDateCar : task?.PrevDateDesc)}}-->
          {{(task?.needCarga == 'Y' ? task?.LocalNameExp : task?.LocalName)}} -
          {{(task?.needCarga == 'Y' ? task?.LocalAddressExp : task?.LocalAddress)}}
          <br>
          {{(task?.needCarga == 'Y' ? ('dashboard.load' | translate) : ('dashboard.unload' | translate))}} -
          {{(task?.needCarga == 'Y' ? task?.PrevDateCar : task?.PrevDateDesc)}}
          {{(task?.DeliveryType ? (' - ' + task?.DeliveryType) : '')}}
          <br>
          {{'tasks.destination' | translate}}: {{task?.WhsName}}
          <span *ngIf="task?.Company"><br>Ferry: {{task?.Company}} - {{task?.PassDate}} {{task?.PassHour}}</span>
        </span>
        <!--<span class="sub-title">{{'tasks.car' | translate}}: {{task?.U_CarPlate}}
          ({{ (task?.U_LineType ==  'R' ? 'Carga' : 'Descarga' ) }})</span>-->
      </ui5-title>
      <!--<ui5-button
        design="Transparent"
        icon="sap-icon://action"
        class="action-button"></ui5-button>-->
      <div class="align-center">
        <ui5-badge *ngIf="task?.RecEnt == 'Entrega'" color-scheme="8">{{task?.RecEnt}}</ui5-badge>
        <ui5-badge *ngIf="task?.RecEnt != 'Entrega'" color-scheme="1">{{task?.RecEnt}}</ui5-badge>
        <br>
        <ui5-button *ngIf="task?.Status == 'End'" class="" design="Transparent" icon="alert"
                    class="status green-text" disabled>{{'tasks.finalized' | translate}}</ui5-button>
        <ui5-button type *ngIf="task?.Status != 'End'" design="Transparent"
                    class="status" icon="away" disabled>{{'tasks.inprogress' | translate}}</ui5-button>
        <div *ngIf="task?.U_Priority === 'FIX'" class="type red-text" style="">{{task?.U_Priority}}</div>
      </div>
    </div>
  </header>
  <div class="details-page">
    <ui5-title level="H3">{{'tasks.details' | translate}}</ui5-title>
    <div class="ui5-content-border details"><span></span><div class="title-details">Proc. {{task?.DocNumProcess}}</div></div>
    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="20px">
      <div fxFlex="50" class="tab1">
        <div *ngIf="taskDetailsD.length <= 1 && taskDetailsD.length <= 1" class="ui5-form-border no-top"></div>
        <ui5-title *ngIf="taskDetailsD.length <= 1 && taskDetailsD.length <= 1" level="H5">
          {{'tasks.origin' | translate}}
        </ui5-title>
        <ui5-tabcontainer class="full-width"
                          [ngClass]="taskDetailsD.length <= 1 && taskDetailsD.length <= 1 ? 'hide-header' : ''"
                          fixed show-overflow>
          <ui5-tab text="{{'tasks.expeditor' | translate}} {{i + 1}} - {{tdC?.Name}} " *ngFor="let tdC of taskDetailsC; let i = index" [selected]="i == 0">
            <!--<div class="ui5-form-border"></div>
            <ui5-title level="H5">Origem</ui5-title>-->
            <div class="ui5-form-border"></div>
            <div class="flex-column margin">
              <ui5-label class="block" for="fullname">{{'tasks.client' | translate}}:</ui5-label>
              <ui5-input placeholder="" class="full-width" value="{{tdC?.Name}}" readonly></ui5-input>
            </div>
            <div class="flex-column margin">
              <ui5-label class="block" for="street">{{'tasks.address' | translate}}:</ui5-label>
              <ui5-input placeholder="" class="full-width"
                         value="{{tdC?.Street}} {{tdC?.City}} {{tdC?.ZipCode}} {{tdC?.Country}}"
                         readonly></ui5-input>
            </div>
            <div fxLayout="row" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px" fxLayoutGap.lt-md="10px"
                 fxLayoutAlign="start center" fxLayoutAlign.lt-md="none none">
              <div fxFlex="50" class="flex-column margin">
                <ui5-label class="block" for="zipcode" >{{'tasks.date' | translate}}:</ui5-label>
                <ui5-input placeholder="" class="full-width" value="{{tdC?.Date}}" readonly></ui5-input>
              </div>
              <div fxFlex="50" class="flex-column margin">
                <ui5-label class="block" for="web">{{'tasks.hour' | translate}}:</ui5-label>
                <ui5-input placeholder="" class="full-width" value="Após as {{tdC?.Time}}" readonly></ui5-input>
              </div>
            </div>
            <div class="flex-column margin">
              <ui5-label class="block" for="fullname">{{'tasks.contact' | translate}}:</ui5-label>
              <ui5-input placeholder="" class="full-width" value="{{tdC?.U_Phone}}" readonly></ui5-input>
            </div>
          </ui5-tab>
        </ui5-tabcontainer>
      </div>

      <div fxFlex="50" class="">
        <div *ngIf="taskDetailsD.length <= 1 && taskDetailsD.length <= 1" class="ui5-form-border no-top"></div>
        <ui5-title *ngIf="taskDetailsD.length <= 1 && taskDetailsD.length <= 1" level="H5">
          {{'tasks.destination' | translate}}</ui5-title>
        <ui5-tabcontainer class="full-width"
                          [ngClass]="taskDetailsD.length <= 1 && taskDetailsD.length <= 1 ? 'hide-header' : ''"
                          fixed show-overflow>
          <ui5-tab text="{{'tasks.recipient' | translate}} {{i + 1}} - {{tdD?.Name}} "
                   *ngFor="let tdD of taskDetailsD; let i = index" [selected]="i == 0">
            <!--<div class="ui5-form-border"></div>
            <ui5-title level="H5">Destino</ui5-title>-->
            <div class="ui5-form-border"></div>
            <div class="flex-column margin">
              <ui5-label class="block" for="fullname">{{'tasks.client' | translate}}:</ui5-label>
              <ui5-input placeholder="" class="full-width" value="{{tdD?.Name}}" readonly></ui5-input>
            </div>
            <div class="flex-column margin">
              <ui5-label class="block" for="street">{{'tasks.address' | translate}}:</ui5-label>
              <ui5-input placeholder="" class="full-width"
                         value="{{tdD?.Street}} {{tdD?.City}} {{tdD?.ZipCode}} {{tdD?.Country}}"
                         readonly></ui5-input>
            </div>
            <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px"
                 fxLayoutGap.lt-md="10px" fxLayoutAlign="start center" fxLayoutAlign.lt-md="none none">
              <div fxFlex="50" class="flex-column margin">
                <ui5-label class="block" for="zipcode" >{{'tasks.date' | translate}}:</ui5-label>
                <ui5-input placeholder="" class="full-width" value="{{tdD?.Date}}" readonly></ui5-input>
              </div>
              <div fxFlex="50" class="flex-column margin">
                <ui5-label class="block" for="web">{{'tasks.hour' | translate}}:</ui5-label>
                <ui5-input placeholder="" class="full-width" value="Após as {{tdD?.Time}}" readonly></ui5-input>
              </div>
            </div>
            <div class="flex-column margin">
              <ui5-label class="block" for="fullname">{{'tasks.contact' | translate}}:</ui5-label>
              <ui5-input placeholder="" class="full-width" value="{{tdD?.U_Phone}}" readonly></ui5-input>
            </div>
          </ui5-tab>

        </ui5-tabcontainer>
      </div>
    </div>

    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="10px">
      <div *ngIf="task?.CountryToCheck == 'GB'" fxFlex="auto" class="extra-fields">
        <ui5-title level="H5" class="span-title">
          {{'tasks.numgmr' | translate}}</ui5-title>
        <span *ngIf="task?.CountryToCheck == 'GB'"
              class="span-text">{{ task?.U_GRMNumber ? task?.U_GRMNumber : ('tasks.nogmrnumber' | translate) }}</span>
        <ui5-button *ngIf="task?.CountryToCheck == 'GB'"
                    [design]="task?.U_GRMNumber ? 'Transparent' : 'Negative'"
                    icon="attachment" class="action-button align-middle" (click)="openFilesGMR(task?.DocEntryPro)"
                    style="margin-left: 5px;border-color: transparent;"></ui5-button>
        <br>
        <ui5-button *ngIf="task?.CountryToCheck == 'GB'"
                    [design]="task?.U_GRMNumber ? 'Default' : 'Negative'"
                    icon="inspection" class="action-button align-middle" (click)="openGMR()"
        >{{'tasks.verifygmrinspection' | translate}}</ui5-button>
      </div>
      <div *ngIf="task?.LoadRef && task?.needCarga == 'Y'" fxFlex="auto" class="extra-fields">
        <ui5-title level="H5" class="span-title">
          {{'tasks.loadref' | translate}}</ui5-title>
        <span class="span-text">{{task?.LoadRef}}</span>
      </div>
      <div *ngIf="task?.GruObs" fxFlex="auto" class="extra-fields">
        <ui5-title level="H5" class="span-title">
          Observações</ui5-title>
        <span class="span-text">{{task?.GruObs}}</span>
      </div>
      <div *ngIf="task?.U_LoadObservations && task?.needCarga == 'Y'" fxFlex="auto" class="extra-fields">
        <ui5-title level="H5" class="span-title">
          {{'tasks.loadobservations' | translate}}</ui5-title>
        <span class="span-text">{{task?.U_LoadObservations}}</span>
      </div>
      <div *ngIf="task?.UnloadRef && task?.needCarga != 'Y'" fxFlex="auto" class="extra-fields">
        <ui5-title level="H5" class="span-title">
          {{'tasks.unloadref' | translate}}</ui5-title>
        <span class="span-text">{{task?.UnloadRef}}</span>
      </div>
      <div *ngIf="task?.U_UnloadObservations && task?.needCarga != 'Y'" fxFlex="auto" class="extra-fields">
        <ui5-title level="H5" class="span-title">
          {{'tasks.unloadobservations' | translate}}</ui5-title>
        <span class="span-text">{{task?.U_UnloadObservations}}</span>
      </div>
      <div *ngIf="task?.U_EnvNumber" fxFlex="auto" class="extra-fields">
        <ui5-title level="H5" class="span-title">
          {{'tasks.envelopenumber' | translate}}</ui5-title>
        <span class="span-text">{{task?.U_EnvNumber}}</span>
      </div>
    </div>

    <div class="margin">
      <br>
      <ui5-title level="H3">{{'tasks.article' | translate}}</ui5-title>
      <div class="ui5-content-border"><span></span></div>
      <br>
      <ui5-table class="demo-table" id="table">
        <!-- Columns -->
        <ui5-table-column *ngIf="task?.Product != 'FTL'" slot="columns" style="width: 12rem">
          <span style="line-height: 1.4rem">{{'tasks.expeditor' | translate}}</span>
        </ui5-table-column>

        <ui5-table-column *ngIf="task?.Product == 'FTL'" slot="columns" style="width: 12rem" >
          <span style="line-height: 1.4rem">{{'tasks.client' | translate}}</span>
        </ui5-table-column>

        <!--<ui5-table-column *ngIf="task?.Product == 'FTL'" slot="columns" min-width="600" >
          <span style="line-height: 1.4rem">{{'tasks.contact' | translate}}</span>
        </ui5-table-column>-->

        <ui5-table-column *ngIf="task?.Product != 'FTL'" slot="columns" min-width="600" popin-text="Destinatário" demand-popin>
          <span style="line-height: 1.4rem">{{'tasks.recipient' | translate}}</span>
        </ui5-table-column>

        <!--<ui5-table-column *ngIf="task?.needCarga != 'Y'" slot="columns" min-width="600" >
          <span style="line-height: 1.4rem">{{'tasks.contact' | translate}}</span>
        </ui5-table-column>-->

        <ui5-table-column slot="columns" min-width="600" popin-text="Qtd." demand-popin>
          <span style="line-height: 1.4rem">{{'tasks.quantity' | translate}}</span>
        </ui5-table-column>

        <ui5-table-column slot="columns" min-width="600" popin-text="Peso" demand-popin>
          <span style="line-height: 1.4rem">{{'tasks.weight' | translate}}</span>
        </ui5-table-column>

        <ui5-table-column slot="columns" min-width="600" popin-text="M3" demand-popin>
          <span style="line-height: 1.4rem">{{'tasks.m3' | translate}}</span>
        </ui5-table-column>

        <ui5-table-column slot="columns" min-width="600" popin-text="Mts. Estrado" demand-popin>
          <span style="line-height: 1.4rem">{{'tasks.platformsize' | translate}}</span>
        </ui5-table-column>

        <ui5-table-column slot="columns" min-width="600" popin-text="Despachos" demand-popin>
          <span style="line-height: 1.4rem">{{'tasks.dispatches' | translate}}</span>
        </ui5-table-column>

        <ui5-table-column slot="columns" style="width: 115px;">
          <span style="line-height: 1.4rem"></span>
          <ui5-button
            *ngIf="task?.RecEnt != 'Recolha'
            && (task?.TypeCollection == 'EAG' || task?.DeliveryType == 'EAG'
            || task?.U_TypeCollection == 'EAG' || task?.U_DeliveryType == 'EAG')"
            icon="decline" [disabled]="unloaded" design="Negative"
            (click)="openUnload({},true)" class="unload-all">
            Desc. Tudo
          </ui5-button>
        </ui5-table-column>

        <ui5-table-row *ngFor="let tsk of taskDetail">
          <ui5-table-cell *ngIf="task?.Product != 'FTL'" class="align-middle">
            <ui5-label class="table-cell-content">
              <b>{{tsk.Expeditor}}</b>
            </ui5-label>
          </ui5-table-cell>
          <ui5-table-cell *ngIf="task?.Product == 'FTL'" class="align-middle">
            <span class="table-cell-content">{{tsk.ClientExpDes}}</span>
          </ui5-table-cell>
          <!--<ui5-table-cell *ngIf="task?.needCarga == 'Y'" class="align-middle">
            <span class="table-cell-content">{{tsk.ExpPhone}}</span>
          </ui5-table-cell>-->
          <ui5-table-cell *ngIf="task?.Product != 'FTL'" class="align-middle">
            <span class="table-cell-content">{{tsk.Destination}}</span>
          </ui5-table-cell>
          <!--<ui5-table-cell *ngIf="task?.needCarga != 'Y'" class="align-middle">
            <span class="table-cell-content">{{tsk.DestinPhone}}</span>
          </ui5-table-cell>-->

          <ui5-table-cell class="align-middle">
            <span class="table-cell-content">{{tsk.Qtd}}</span>
          </ui5-table-cell>
          <ui5-table-cell class="align-middle">
            <span class="table-cell-content">{{tsk.Weight}}</span>
          </ui5-table-cell>
          <ui5-table-cell class="align-middle">
            <span class="table-cell-content">{{tsk.M3}}</span>
          </ui5-table-cell>
          <ui5-table-cell class="align-middle">
            <span class="table-cell-content">{{tsk.U_LoadMeters}}</span>
          </ui5-table-cell>
          <ui5-table-cell class="align-middle">
            <ui5-button [disabled]="tsk?.hasDispach == 0"
                        design="Transparent"
                        icon="customer-financial-fact-sheet"
                        class="action-button" (click)="openFilesDispatches(tsk.DocEntry)">{{tsk?.hasDispach}}</ui5-button>
          </ui5-table-cell>
          <ui5-table-cell>
            <!------ Force Enable to Allow Demo & Tests ---------->
            <!--<ui5-button *ngIf="task?.needCarga == 'Y'" icon="approvals"
                        design="Positive" (click)="openLoad(tsk)">
              {{'tasks.load' | translate}}
            </ui5-button>
            <ui5-button *ngIf="task?.needCarga != 'Y'" icon="decline"
                        design="Negative" (click)="openUnload(tsk)">
              {{'tasks.unload' | translate}}
            </ui5-button>-->
            <ui5-button *ngIf="task?.RecEnt != 'Recolha'" icon="decline" [disabled]="tsk?.unloadDisabled"
                        design="Negative" (click)="openUnload(tsk)">
              {{'tasks.unload' | translate}}
            </ui5-button>
            <ui5-button *ngIf="task?.RecEnt == 'Recolha'" icon="approvals" [disabled]="tsk?.loadDisabled"
                        design="Positive" (click)="openLoad(tsk)">
              {{'tasks.load' | translate}}
            </ui5-button>
          </ui5-table-cell>
        </ui5-table-row>

      </ui5-table>
    </div>

  </div>

  <div slot="footer" class="footer-sticky">
    <ui5-bar design="Footer">
      <!--<ui5-button *ngIf="task?.CountIncidence" icon="incident"
                  slot="startContent"  class="margin-btn" design="Default" (click)="openIncidences()">{{'tasks.incidences' | translate}}</ui5-button>-->
      <!--<ui5-button design="Transparent" slot="endContent" routerLink="/tasks" >{{'general.back' | translate}}</ui5-button>-->
      <ui5-button icon="undo" design="Transparent" slot="startContent" (click)="goToTasks()" >{{'general.back' | translate}}</ui5-button>
      <!--<ui5-button icon="map-3" design="Default" slot="endContent" (click)="openMap()">Google Maps</ui5-button>-->
      <ui5-button *ngIf="task?.CountIncidence" icon="incident"
                  slot="endContent"  class="margin-btn" design="Default" (click)="openIncidences()">{{'tasks.incidences' | translate}}</ui5-button>
    </ui5-bar>
  </div>

  <!--- Dialogs -->

  <!-- Load -->
  <ui5-dialog #dialogLoad class="dialog-load" headerText="{{'tasks.confirmload' | translate}}" >
    <div [formGroup]="formLoad" class="dialog-content">
      <div class="flex-column margin">
        <ui5-label class="block" for="Qtd" >Qtd:</ui5-label>
        <ui5-input type="Number" placeholder="" class="full-width" formControlName="Qtd"
                   ngDefaultControl oragami></ui5-input>
      </div>
      <div class="flex-column margin">
        <ui5-label class="block" for="Weight" >{{'tasks.weight' | translate}}:</ui5-label>
        <ui5-input type="Number" placeholder="" class="full-width" formControlName="Weight"
                   ngDefaultControl oragami></ui5-input>
      </div>
      <div class="flex-column margin">
        <ui5-label class="block" for="incidents">{{'tasks.incidences' | translate}}:</ui5-label>
        <ui5-button class="full-width" icon="incident" (click)="openIncidence()"></ui5-button>
      </div>
      <div class="flex-column margin">
        <ui5-label class="block" for="Kms" required>Kms:</ui5-label>
        <ui5-input #kmsLoad type="Number" placeholder="" class="full-width" formControlName="Kms"
                   ngDefaultControl oragami></ui5-input>
      </div>
      <div class="flex-column margin">
        <ui5-label class="block" for="Date" required>{{'tasks.date' | translate}}:</ui5-label>
        <ui5-date-picker #dateLoad class="full-width" formControlName="Date"
                         format-pattern="yyyy-MM-dd" ngDefaultControl origami></ui5-date-picker>
      </div>
      <div class="flex-column margin">
        <ui5-label class="block" for="Hora" required>{{'tasks.hour' | translate}}:</ui5-label>
        <ui5-time-picker #timeLoad  class="full-width" formControlName="Hora"
                         format-pattern="HH:mm" ngDefaultControl origami></ui5-time-picker>
      </div>
      <div class="flex-column margin">
        <ui5-label class="block" for="Description">{{'tasks.description' | translate}}:</ui5-label>
        <ui5-textarea placeholder="" class="full-width" formControlName="Description"
                      ngDefaultControl oragami></ui5-textarea>
      </div>
    </div>
    <div slot="footer" class="dialog-footer fixed">
      <div>
        <ui5-button icon="undo" class="margin-btn" design="Transparent" (click)="closeLoad()">{{'general.back' | translate}}</ui5-button>
        <ui5-button icon="accept" design="Emphasized" (click)="saveLoad()">{{'general.confirm' | translate}}</ui5-button>
      </div>
    </div>
  </ui5-dialog>
  <!-- Unload -->
  <ui5-dialog #dialogUnload class="dialog-unload" headerText="{{'tasks.confirmunload' | translate}}">
    <div [formGroup]="formUnload" class="dialog-content">
      <div *ngIf="!unloadAll" class="flex-column margin">
        <ui5-label class="block" for="Qtd" >Qtd:</ui5-label>
        <ui5-input type="Number" placeholder="" class="full-width" formControlName="Qtd"
                   ngDefaultControl oragami></ui5-input>
      </div>
      <div *ngIf="!unloadAll" class="flex-column margin">
        <ui5-label class="block" for="incidents">{{'tasks.incidences' | translate}}:</ui5-label>
        <ui5-button class="full-width" icon="incident" (click)="openIncidence()"></ui5-button>
      </div>
      <div class="flex-column margin">
        <ui5-label class="block" for="Kms" required>Kms:</ui5-label>
        <ui5-input #kmsUnload type="Number" placeholder="" class="full-width" formControlName="Kms"
                   ngDefaultControl oragami></ui5-input>
      </div>
      <div class="flex-column margin">
        <ui5-label class="block" for="Date" required>{{'tasks.date' | translate}}:</ui5-label>
        <ui5-date-picker #dateUnload class="full-width" formControlName="Date"
                         format-pattern="yyyy-MM-dd" ngDefaultControl origami></ui5-date-picker>
      </div>
      <div class="flex-column margin">
        <ui5-label class="block" for="Hora" required>{{'tasks.hour' | translate}}:</ui5-label>
        <ui5-time-picker #timeUnload class="full-width" formControlName="Hora"
                         format-pattern="HH:mm" ngDefaultControl origami></ui5-time-picker>
      </div>
      <div class="flex-column margin">
        <ui5-label class="block" for="Unload" required>{{'tasks.unloadtime' | translate}} (min):</ui5-label>
        <ui5-input #unloadTime type="Number" placeholder="" class="full-width" formControlName="Unload"
                   ngDefaultControl oragami></ui5-input>
      </div>
      <div class="flex-column margin">
        <ui5-label class="block" for="Description">{{'tasks.description' | translate}}:</ui5-label>
        <ui5-textarea placeholder="" class="full-width" formControlName="Description"
                      ngDefaultControl oragami></ui5-textarea>
      </div>
      <div class="flex-column margin">
        <ui5-label class="block" for="Destination" required>{{'tasks.destination' | translate}}:</ui5-label>
        <ui5-input #destinationUnload placeholder="" class="full-width" formControlName="Destination"
                   ngDefaultControl oragami></ui5-input>
      </div>
      <div class="flex-column margin">
        <ui5-label class="block" for="fotosCMR" required>{{'tasks.cmrphoto' | translate}}:</ui5-label>
        <ui5-button class="full-width" (click)="openFiles(articleId)" icon="attachment">{{fotosCMR?.length}}</ui5-button>
      </div>
      <div *ngIf="signature" class="flex-column margin">
        <ui5-label class="block" for="Sign">{{'tasks.signature' | translate}}:</ui5-label>
        <ui5-button (click)="openSign(articleId)" design="Emphasized">{{'tasks.sign' | translate}}</ui5-button>
        <ui5-checkbox style="margin-left: 5px;" formControlName="Sign"
                      (change)="formUnload.controls['Sign'].setValue($event['target']['checked'])"
                      [checked]="formUnload.controls['Sign']['value']"
                      ngDefaultControl text="Com Assinatura" oragami></ui5-checkbox>
      </div>
    </div>
    <div slot="footer" class="dialog-footer fixed">
      <div>
        <ui5-button icon="undo" class="margin-btn" design="Transparent" (click)="closeUnload(true)">{{'general.back' | translate}}</ui5-button>
        <ui5-button icon="accept" design="Emphasized" (click)="saveUnload()">{{'general.confirm' | translate}}</ui5-button>
      </div>
    </div>
  </ui5-dialog>
  <!-- Incidences -->
  <ui5-dialog #dialogIncidence headerText="{{'tasks.createincidences' | translate}}" >
    <div [formGroup]="formIncidence" style="padding: 0 2rem 1rem;">
      <div class="flex-column margin">
        <ui5-label class="block" for="Motive" required>{{'tasks.motive' | translate}}:</ui5-label>
        <ui5-select #motiveIncidence placeholder=""
                    class="select" class="full-width"
                    (change)="formIncidence.controls['Motive'].setValue($event['detail']['selectedOption']['value'])"
                    formControlName="Motive" ngDefaultControl oragami>
          <ui5-option *ngFor="let ic of incidenceTypes" [value]="ic.Code"
                      [selected]="ic.Code == formIncidence.controls['Motive']['value']">{{ic.Name}}</ui5-option>
        </ui5-select>
      </div>

      <div class="flex-column margin">
        <ui5-label class="block" for="Date" required>{{'tasks.date' | translate}}:</ui5-label>
        <ui5-date-picker #dateIncidence class="full-width" formControlName="Date"
                         format-pattern="yyyy-MM-dd" ngDefaultControl origami></ui5-date-picker>
      </div>
      <div class="flex-column margin">
        <ui5-label class="block" for="Hora" required>{{'tasks.hour' | translate}}:</ui5-label>
        <ui5-time-picker #timeIncidence class="full-width" formControlName="Hora"
                         format-pattern="HH:mm" ngDefaultControl origami></ui5-time-picker>
      </div>
      <div class="flex-column margin">
        <ui5-label class="block" for="Observations">{{'tasks.observations' | translate}}:</ui5-label>
        <ui5-textarea rows="5" placeholder="" class="full-width" formControlName="Observations"
                      ngDefaultControl oragami></ui5-textarea>
      </div>
      <div class="flex-column margin">
        <ui5-label class="block" for="filesIncidences">{{'tasks.attachements' | translate}}:</ui5-label>
        <ui5-button class="full-width" (click)="openFilesInc(articleId)" icon="attachment">{{filesIncidences?.length}}</ui5-button>
      </div>
    </div>
    <div slot="footer" class="dialog-footer fixed">
      <div>
        <ui5-button icon="undo" class="margin-btn" design="Transparent" (click)="closeIncidence(true)">{{'general.back' | translate}}</ui5-button>
        <ui5-button icon="accept" design="Emphasized" (click)="saveIncidence()">{{'general.submit' | translate}}</ui5-button>
      </div>
    </div>
  </ui5-dialog>
  <!-- Incidences List -->
  <ui5-dialog #dialogIncidences stretch="true" headerText="{{'tasks.incidences' | translate}}" >
    <div style="padding: 0 1rem 1rem;">
      <br>
      <ui5-title>{{'tasks.incidences' | translate}} ({{incidences?.length}})</ui5-title>
      <br>
      <ui5-table class="demo-table" id="table">
        <ui5-table-column slot="columns" style="width: 12rem">
          <span style="line-height: 1.4rem">{{'tasks.reserve' | translate}}</span>
        </ui5-table-column>
        <ui5-table-column slot="columns" min-width="800" popin-text="Num" demand-popin>
          <span style="line-height: 1.4rem">Num</span>
        </ui5-table-column>
        <ui5-table-column slot="columns" min-width="600" popin-text="{{'tasks.motive' | translate}}" demand-popin>
          <span style="line-height: 1.4rem">{{'tasks.motive' | translate}}</span>
        </ui5-table-column>
        <ui5-table-column slot="columns" min-width="600" popin-text="{{'tasks.date' | translate}}" demand-popin>
          <span style="line-height: 1.4rem">{{'tasks.date' | translate}}</span>
        </ui5-table-column>
        <ui5-table-row *ngFor="let inc of incidences">
          <ui5-table-cell class="align-middle">
            <span class="table-cell-content">{{inc.ResDoc}}</span>
          </ui5-table-cell>
          <ui5-table-cell class="align-middle">
            <span class="table-cell-content">{{inc.DocNum}}</span>
          </ui5-table-cell>
          <ui5-table-cell class="align-middle">
            <span class="table-cell-content">{{inc.Motive}}</span>
          </ui5-table-cell>
          <ui5-table-cell class="align-middle">
            <span class="table-cell-content">{{inc.Date}}</span>
          </ui5-table-cell>
        </ui5-table-row>
      </ui5-table>
    </div>
    <div slot="footer" class="dialog-footer fixed">
      <ui5-button icon="undo" class="margin-btn" design="Transparent" (click)="closeIncidences()">{{'general.back' | translate}}</ui5-button>
    </div>
  </ui5-dialog>
  <!-- Files -->
  <ui5-dialog #dialogFilesDispatches
              headerText="{{ gmr ? 'GMR' : ('tasks.dispatches' | translate) }}" >
    <div style="padding: 0 1rem 1rem;">
      <div slot="header" class="header">
        <ui5-title>{{'general.files' | translate}} ({{filesDispatches?.length}})</ui5-title>
        <div class="spacer"></div>
      </div>
      <ui5-upload-collection mode="None"
                             noDataText="{{'general.nofiles' | translate}}"
                             noDataDescription="{{'general.nofilesdescription' | translate}}">
        <ui5-upload-collection-item
          *ngFor="let file of filesDispatches"
          [fileName]="file?.title + ' - Download'"
          [title]="file?.id"
          (click)="downloadFile($event,file?.href,file?.filename)"
          file-name-clickable
          disable-delete-button
          hide-delete-button
          type=""
          upload-state="Complete">
          <!--<ui5-title level="H4">{{file?.title}}</ui5-title>-->
          <img *ngIf="file?.ThumbnailUrl" src="{{file?.ThumbnailUrl}}" slot="thumbnail">
          <ui5-icon *ngIf="!file?.ThumbnailUrl" name="document-text" slot="thumbnail"></ui5-icon>
          {{'general.uploadedby' | translate}}: {{file?.nomeUser}} · {{'general.in' | translate}}:
          {{file?.createddate | date:'yyyy-MM-dd'}} · {{'general.size' | translate}}: {{file?.filesize}} KB
          <br><ui5-link class="preview-link" (click)="previewFile($event,file?.href,file?.filename);">Pre-visualizar</ui5-link>
        </ui5-upload-collection-item>
      </ui5-upload-collection>
    </div>
    <div slot="footer" class="dialog-footer">
      <div>
        <ui5-button icon="undo" class="margin-btn" design="Transparent" (click)="closeFilesDispatches()">{{'general.back' | translate}}</ui5-button>
      </div>
    </div>
  </ui5-dialog>
  <!-- Files -->
  <ui5-dialog #dialogFilesInc  headerText="{{'tasks.attachements' | translate}}" >
    <div style="padding: 0 1rem 1rem;">
      <div slot="header" class="header">
        <ui5-title>{{'general.files' | translate}} ({{filesIncidences?.length}})</ui5-title>
        <div class="spacer"></div>
        <ui5-file-uploader #incFile id="incFile" (click)="clearFiles($event)" hide-input>
          <ui5-button icon="add" design="Transparent">Upload</ui5-button>
        </ui5-file-uploader>
      </div>
      <ui5-upload-collection #filesCollection
                             id="filesCollection"
                             mode="Delete"
                             noDataText="{{'general.nofiles' | translate}}"
                             noDataDescription="{{'general.draghere' | translate}}">
        <ui5-upload-collection-item
          *ngFor="let file of filesIncidences"
          [fileName]="file?.title + ' - Download'"
          [title]="file?.id"
          (click)="downloadFile($event,file?.href,file?.filename)"
          file-name-clickable
          type=""
          upload-state="Complete">
          <!--<ui5-title level="H4">{{file?.title}}</ui5-title>-->
          <img *ngIf="file?.ThumbnailUrl" src="{{file?.ThumbnailUrl}}" slot="thumbnail">
          <ui5-icon *ngIf="!file?.ThumbnailUrl" name="document-text" slot="thumbnail"></ui5-icon>
          {{'general.uploadedby' | translate}}: {{file?.nomeUser}} · {{'general.in' | translate}}:
          {{file?.createddate | date:'yyyy-MM-dd'}} · {{'general.size' | translate}}: {{file?.filesize}} KB
          <br><ui5-link class="preview-link" (click)="previewFile($event,file?.href,file?.filename);">Pre-visualizar</ui5-link>
        </ui5-upload-collection-item>
      </ui5-upload-collection>
    </div>
    <div slot="footer" class="dialog-footer">
      <div>
        <ui5-button icon="undo" class="margin-btn" design="Transparent" (click)="closeFilesInc()">{{'general.back' | translate}}</ui5-button>
      </div>
    </div>
  </ui5-dialog>
  <!-- Files -->
  <ui5-dialog #dialogFiles  header-text="Anexar Foto CMR" >
    <div style="padding: 0 1rem 1rem;">
      <div slot="header" class="header">
        <ui5-title>{{'general.files' | translate}} ({{fotosCMR?.length}})</ui5-title>
        <div class="spacer"></div>
        <ui5-file-uploader #fotosFile id="fotosFile" (click)="clearFiles($event)" hide-input>
          <ui5-button icon="add" design="Transparent">Upload</ui5-button>
        </ui5-file-uploader>
      </div>
      <ui5-upload-collection #fotosCollection
                             id="fotosCollection"
                             mode="Delete"
                             noDataText="{{'general.nofiles' | translate}}"
                             noDataDescription="{{'general.draghere' | translate}}">
        <ui5-upload-collection-item
          *ngFor="let foto of fotosCMR"
          [fileName]="foto?.title + ' - Download'"
          [title]="foto?.id"
          (click)="downloadFile($event,foto?.href,foto?.filename)"
          file-name-clickable
          type=""
          upload-state="Complete">
          <!--<ui5-title level="H4">{{file?.title}}</ui5-title>-->
          <img *ngIf="foto?.ThumbnailUrl" src="{{foto?.ThumbnailUrl}}" slot="thumbnail">
          <ui5-icon *ngIf="!foto?.ThumbnailUrl" name="document-text" slot="thumbnail"></ui5-icon>
          Carregado Por: {{foto?.nomeUser}} · Em: {{foto?.createddate | date:'yyyy-MM-dd'}} · Tamanho: {{foto?.filesize}} KB
          <br><ui5-link class="preview-link" (click)="previewFile($event,foto?.href,foto?.filename);">Pre-visualizar</ui5-link>
        </ui5-upload-collection-item>
      </ui5-upload-collection>
    </div>
    <div slot="footer" class="dialog-footer">
      <div>
        <ui5-button icon="undo" class="margin-btn" design="Transparent" (click)="closeFiles()">{{'general.back' | translate}}</ui5-button>
      </div>
    </div>
  </ui5-dialog>
  <!-- File Preview -->
  <ui5-dialog #dialogPreview header-text="{{'tasks.maps' | translate}}" stretch>
    <div class="dialog-content dialog-preview">
      <ngx-extended-pdf-viewer
        *ngIf="isPdf"
        [src]="fileViewer?.src.split(',').pop()"
        backgroundColor="#E5E5E5"
        theme="dark"
        [filenameForDownload]="fileViewer?.filename"
        [useBrowserLocale]="true"
        [handTool]="false"
        [height]="'100%'"
        [showSidebarButton]="false"
        [showHandToolButton]="false"
        [showPrintButton]="true"
        [showDownloadButton]="true"
        [showOpenFileButton] ="false"
        [showBookmarkButton] ="false"
        [showSecondaryToolbarButton] ="true"
        [delayFirstView]="1">
      </ngx-extended-pdf-viewer>
      <div *ngIf="!isPdf" class="preview">
        <img [src]="fileViewer?.src" >
      </div>
    </div>
    <div slot="footer" class="dialog-footer fixed">
      <ui5-button icon="undo" class="margin-btn" design="Transparent" (click)="closeDialogPreview()">
        {{'general.back' | translate}}</ui5-button>
    </div>
  </ui5-dialog>
  <!-- Signature -->
  <ui5-dialog #dialogSign stretch="true" headerText="{{'tasks.signature' | translate}}" >
    <div class="signature-container">
      <signature-pad [options]="signaturePadOptions" ></signature-pad>
    </div>
    <div slot="footer" class="dialog-footer">
      <div>
        <ui5-button icon="undo" class="margin-btn" design="Transparent" (click)="closeSign()">{{'general.back' | translate}}</ui5-button>
        <ui5-button design="Emphasized" (click)="saveSign()">{{'general.submit' | translate}}</ui5-button>
      </div>
    </div>
  </ui5-dialog>
  <!-- Map -->
  <ui5-dialog #dialogMap stretch="true" header-text="Google Maps">
    <div class="dialog-content" style="padding: 0.5rem 0.5rem;">
      <agm-map class="map-fullwith"
               [latitude]="latitude"
               [longitude]="longitude"
               [zoom]="8"
               [disableDefaultUI]="false">
        <agm-marker (markerClick)="clickedMarker(task?.LocalAddressDestl, 1)"
                    [latitude]="latitude"
                    [longitude]="longitude"
                    [label]=""
                    [iconUrl]="">
          <agm-info-window>
            <strong style="color: #000;">{{task?.LocalAddressDest}}</strong>
          </agm-info-window>
        </agm-marker>
      </agm-map>
    </div>
    <div slot="footer" class="dialog-footer fixed">
      <ui5-button icon="undo" class="margin-btn" design="Transparent" (click)="closeMap()">{{'general.back' | translate}}</ui5-button>
      <ui5-button design="Emphasized" (click)="openGoogleMaps()" icon="locate-me">{{'tasks.navigate' | translate}}</ui5-button>
    </div>
  </ui5-dialog>
  <!-- Trailer State  -->
  <ui5-dialog #dialogTrailerState class="dialog-trailers" headerText="{{'checkinout.trailer' | translate}} - {{trailerCode}}" >
    <div [formGroup]="formTrailerState" class="dialog-content">
      <div class="flex-column margin">
        <ui5-label class="block" for="LocalChange" required>{{'tasks.localchange' | translate}}:</ui5-label>
        <ui5-select #localchange placeholder=""
                    class="select" class="full-width"
                    (change)="formTrailerState.controls['LocalChange'].setValue($event['detail']['selectedOption']['value']);"
                    formControlName="LocalChange" ngDefaultControl required oragami>
          <ui5-option *ngFor="let l of localChange" [value]="l.Code"
                      [selected]="l.Code == formTrailerState.controls['LocalChange']['value']">{{l.Name}}</ui5-option>
        </ui5-select>
      </div>
      <div class="flex-column margin" *ngIf="formTrailerState.controls['LocalChange']['value'] == 'NOT'">
        <ui5-label class="block" for="NotherLocal" required>{{'tasks.local' | translate}}:</ui5-label>
        <ui5-input placeholder="" class="full-width" formControlName="NotherLocal"
                   ngDefaultControl oragami></ui5-input>
      </div>
      <div class="flex-column margin">
        <ui5-label class="block" for="Strap" required>{{'tasks.strap' | translate}}:</ui5-label>
        <ui5-input #strap type="Number" placeholder="" class="full-width" formControlName="Strap"
                   ngDefaultControl oragami></ui5-input>
      </div>
      <div class="flex-column margin">
        <ui5-label class="block" for="Rulers" required>{{'tasks.rulers' | translate}}:</ui5-label>
        <ui5-input #rulers type="Number" placeholder="" class="full-width" formControlName="Rulers"
                   ngDefaultControl oragami></ui5-input>
      </div>

      <div class="flex-column ">
        <ui5-checkbox style="margin-left: -10px;" formControlName="Canvas"
                      (change)="formTrailerState.controls['Canvas'].setValue($event['target']['checked'])"
                      [checked]="formTrailerState.controls['Canvas']['value']"
                      text="{{'tasks.canvas' | translate}}" ngDefaultControl oragami></ui5-checkbox>
      </div>

      <div class="flex-column ">
        <ui5-checkbox style="margin-left: -10px;" formControlName="Tyres"
                      (change)="formTrailerState.controls['Tyres'].setValue($event['target']['checked'])"
                      [checked]="formTrailerState.controls['Tyres']['value']"
                      ngDefaultControl text="{{'tasks.tyres' | translate}}" oragami></ui5-checkbox>
      </div>

      <div class="flex-column ">
        <ui5-checkbox style="margin-left: -10px;" formControlName="Trailer"
                      (change)="formTrailerState.controls['Trailer'].setValue($event['target']['checked'])"
                      [checked]="formTrailerState.controls['Trailer']['value']"
                      ngDefaultControl text="{{'tasks.trailer' | translate}}" oragami></ui5-checkbox>
      </div>

      <div class="flex-column margin">
        <ui5-label class="block" for="Observations">{{'tasks.observations' | translate}}:</ui5-label>
        <ui5-textarea rows="5" placeholder="" class="full-width" formControlName="Observations"
                      ngDefaultControl oragami></ui5-textarea>
      </div>
    </div>
    <div slot="footer" class="dialog-footer fixed">
      <div>
        <ui5-button icon="undo" class="margin-btn" design="Transparent" (click)="closeDialogTrailerState()">{{'general.back' | translate}}</ui5-button>
        <ui5-button design="Emphasized" (click)="saveTrailerState()">{{'general.confirm' | translate}}</ui5-button>
      </div>
    </div>
  </ui5-dialog>
</ui5-page>
