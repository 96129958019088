import { NgModule, CUSTOM_ELEMENTS_SCHEMA, APP_INITIALIZER } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { ServiceWorkerModule } from '@angular/service-worker';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PortalModule } from '@angular/cdk/portal';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NgxChatModule } from '@pazznetwork/ngx-chat';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
//import { DragulaModule } from 'ng2-dragula';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { environment } from '../environments/environment';

import { AppComponent } from './app.component';
import { OrigamiFormsModule } from '@codebakery/origami/forms';
import { LoginComponent } from './pages/login/login.component';
import { AuthGuard } from './auth/auth.guard';
import { AuthService } from './auth/auth.service';
import { AlertService } from './services/alert.service';
import { HttpErrorInterceptor } from './interceptors/http-error.interceptor';
import { HeaderComponent } from './shared/header/header.component';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { TasksComponent } from './pages/tasks/tasks.component';
import { TaskDetailComponent } from './pages/task-detail/task-detail.component';
import { MaintenanceComponent } from './pages/maintenance/maintenance.component';
import { CheckinoutComponent } from './pages/checkinout/checkinout.component';
import { RefuellingComponent } from './pages/refuelling/refuelling.component';
import { CardsComponent } from './pages/cards/cards.component';
import { HistoryComponent } from './pages/history/history.component';
import { LinksComponent } from './pages/links/links.component';
import { MessagesComponent } from './shared/messages/messages.component';
import { LoadingComponent } from './shared/dialogs/loading/loading.component';
import { NotificationComponent } from './shared/dialogs/notification/notification.component';
import { AlertComponent } from './shared/dialogs/alert/alert.component';
import { AppRoutingModule } from './app-routing.module';
//import { CalendarModule, DateAdapter } from 'angular-calendar';
import { AgmCoreModule } from '@agm/core';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { SignaturePadModule } from 'angular2-signaturepad';
//import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
registerLocaleData(localePt);
/*import * as moment from 'moment';
export function momentAdapterFactory() {
  return adapterFactory(moment);
};*/

// UI5 Web Components used
import '@ui5/webcomponents/dist/Icon';
import '@ui5/webcomponents/dist/Assets';
import '@ui5/webcomponents/dist/Button';
import "@ui5/webcomponents/dist/SplitButton.js";
import '@ui5/webcomponents/dist/Avatar';
import '@ui5/webcomponents/dist/Title';
import '@ui5/webcomponents/dist/Input';
import '@ui5/webcomponents/dist/DatePicker';
import '@ui5/webcomponents/dist/TimePicker.js';
import '@ui5/webcomponents/dist/List';
import '@ui5/webcomponents/dist/CustomListItem';
import '@ui5/webcomponents/dist/Panel';
import '@ui5/webcomponents/dist/Dialog';
import '@ui5/webcomponents/dist/Label';
import '@ui5/webcomponents/dist/TextArea';
import '@ui5/webcomponents/dist/Popover';
import '@ui5/webcomponents/dist/Panel';
import '@ui5/webcomponents/dist/Card';
import "@ui5/webcomponents/dist/CardHeader.js";
import '@ui5/webcomponents/dist/Title';
import '@ui5/webcomponents/dist/Table';
import '@ui5/webcomponents/dist/TableColumn';
import '@ui5/webcomponents/dist/TableRow';
import '@ui5/webcomponents/dist/TableCell';
import "@ui5/webcomponents/dist/TabContainer";
import "@ui5/webcomponents/dist/Tab";
import "@ui5/webcomponents/dist/TabSeparator";
import '@ui5/webcomponents/dist/Badge';
import '@ui5/webcomponents/dist/SegmentedButton';
import '@ui5/webcomponents/dist/Select';
import '@ui5/webcomponents/dist/Option';
import '@ui5/webcomponents/dist/ComboBox';
import "@ui5/webcomponents/dist/MultiComboBox";
import '@ui5/webcomponents/dist/CheckBox';
import '@ui5/webcomponents/dist/StandardListItem';
import '@ui5/webcomponents/dist/MessageStrip';
import '@ui5/webcomponents/dist/Toast';
import '@ui5/webcomponents/dist/FileUploader.js';
import '@ui5/webcomponents-fiori/dist/BarcodeScannerDialog';
import '@ui5/webcomponents-icons/dist/AllIcons.js';
import '@ui5/webcomponents-fiori/dist/Bar.js';
import '@ui5/webcomponents-fiori/dist/FlexibleColumnLayout';
//import '@ui5/webcomponents-fiori/dist/ShellBar';
import '@ui5/webcomponents-fiori/dist/ShellBarItem';
import '@ui5/webcomponents-fiori/dist/SideNavigation';
import '@ui5/webcomponents-fiori/dist/SideNavigationItem';
import '@ui5/webcomponents-fiori/dist/SideNavigationSubItem';
import '@ui5/webcomponents-fiori/dist/Timeline';
//import '@ui5/webcomponents-fiori/dist/features/CoPilotAnimation.js';
import '@ui5/webcomponents-fiori/dist/UploadCollection.js';
import '@ui5/webcomponents-fiori/dist/UploadCollectionItem.js';
import '@ui5/webcomponents-fiori/dist/Assets';
//import '@ui5/webcomponents-theme-base/dist/Assets';
import '@ui5/webcomponents/dist/generated/json-imports/Themes';
import '@ui5/webcomponents-fiori/dist/generated/json-imports/Themes';
import '@ui5/webcomponents/dist/features/InputElementsFormSupport.js';
import ShellBar from '@ui5/webcomponents-fiori/dist/ShellBar';
export function onAppInitShell(): () => Promise<any> {
  return (): Promise<any> => {
    return ShellBar.define();
  };
}

@NgModule({
  imports: [
    OrigamiFormsModule,
    PortalModule,
    NgxChartsModule,
    NgxDatatableModule,
    HttpClientModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    // ngx-translate and the loader module
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
    }),
    //CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory }),
    //DragulaModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDw9eutz_DThFEKNMxjTptv5PfUaHev_Z4'
    }),
    NgxExtendedPdfViewerModule,
    NgxChatModule.forRoot(),
    Ng2SearchPipeModule,
    SignaturePadModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      //enabled: environment.production,
      enabled: (window['_cordovaNative'] ? false : true),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  declarations: [
    AppComponent,
    LoginComponent,
    HeaderComponent,
    SidebarComponent,
    DashboardComponent,
    TasksComponent,
    TaskDetailComponent,
    MaintenanceComponent,
    CheckinoutComponent,
    RefuellingComponent,
    CardsComponent,
    HistoryComponent,
    LinksComponent,
    MessagesComponent,
    LoadingComponent,
    NotificationComponent,
    AlertComponent
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  providers: [
    AuthService,
    AuthGuard,
    LoadingComponent,
    NotificationComponent,
    AlertComponent,
    AlertService,
    {
      provide: APP_INITIALIZER,
      useFactory: onAppInitShell,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    }
  ],
  bootstrap: [ AppComponent ]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}


