<ui5-shellbar
  #shellbar
  id="shellbar"
  primary-title="User Portal"
  secondary-title=""
  show-product-switch
  show-co-pilot
  [hidden]="!(isLoggedIn$ | async)">
  <!-- show-co-pilot
  notification-count="0"
  show-notifications -->
  <!--<ui5-avatar slot="profile" image="/assets/images/woman_avatar_5.png"></ui5-avatar>-->
  <ui5-avatar slot="profile" icon="customer"></ui5-avatar>
  <img slot="logo" src="/assets/images/sap-logo-svg.svg"/>
  <ui5-button #menu id="menu" icon="menu2" slot="startButton"></ui5-button>
  <ui5-shellbar-item #connection id="connection" icon="connected" text="{{'header.connected' | translate}}"></ui5-shellbar-item>
  <ui5-shellbar-item #language id="language" icon="world" text="{{'header.changelanguage' | translate}}"></ui5-shellbar-item>
  <ui5-shellbar-item #chatBtn id="chatBtn" class="chatBtn" icon="discussion" text="Chat({{unreadMessageCountPlugin.unreadMessageCountSum$ | async}})"
                     count="{{unreadMessageCountPlugin.unreadMessageCountSum$ | async}}"></ui5-shellbar-item>
  <ui5-input slot="searchField"></ui5-input>
  <ui5-li slot="menuItems" class="menu-dashboard">{{ 'menu.home' | translate }} </ui5-li>
  <ui5-li slot="menuItems" class="menu-checkinout">Check-in/out </ui5-li>
  <ui5-li slot="menuItems" class="menu-refuelling">{{ 'menu.refulling' | translate }} </ui5-li>
  <ui5-li slot="menuItems" class="menu-tasks">{{ 'menu.tasks' | translate }} </ui5-li>
  <ui5-li slot="menuItems" class="menu-maintenance">{{ 'menu.maintenance' | translate }} </ui5-li>
  <ui5-li slot="menuItems" class="menu-cards">{{ 'menu.cards' | translate }} </ui5-li>
</ui5-shellbar>

<ui5-popover #popover id="popover" placement-type="Bottom">
  <div class="popover-header">
    <ui5-title class="title-username">{{user}}</ui5-title>
  </div>
  <div class="popover-content">
    <ui5-list separators="None" >
      <!--<ui5-li icon="sys-find">App Finder</ui5-li>
      <ui5-li icon="settings">Settings</ui5-li>-->
      <!--<ui5-li icon="discussion" (click)="toogleChat()">
      Chat({{unreadMessageCountPlugin.unreadMessageCountSum$ | async}})</ui5-li>-->
      <ui5-li icon="edit" (click)="toggleTheme()">{{'header.theme' | translate}}</ui5-li>
      <!--<ui5-li icon="sys-help">Help</ui5-li>-->
      <ui5-li icon="log" (click)="onLogout()">{{'header.logout' | translate}}</ui5-li>
    </ui5-list>
  </div>
</ui5-popover>

<ui5-popover #popoverLang id="popoverLang" placement-type="Bottom">
  <!--<div class="popover-header">
    <ui5-title style="padding: 0.25rem 1rem 0rem 1rem"></ui5-title>
  </div>-->
  <div class="popover-content">
    <ui5-list separators="None" >
      <ui5-li icon="flag" (click)="changeLanguage('pt')">Português</ui5-li>
      <ui5-li icon="flag" (click)="changeLanguage('en')">English</ui5-li>
    </ui5-list>
  </div>
</ui5-popover>

<ui5-toast #wcConnectionOn placement="TopCenter"><span style="color:#107e3e">Server Online</span></ui5-toast>
<ui5-toast #wcConnectionOff placement="TopCenter"><span style="color:#b00">Server Offline</span></ui5-toast>
<ui5-toast #wcNotification placement="TopCenter"><span>No new notifications</span></ui5-toast>
