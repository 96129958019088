import {Component, Inject, OnInit} from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';

import { setTheme } from '@ui5/webcomponents-base/dist/config/Theme';
import { setLanguage } from '@ui5/webcomponents-base/dist/config/Language';
import { TranslateService } from '@ngx-translate/core';
import {
  ChatService,
  ChatServiceToken,
  MultiUserChatPlugin,
  ChatBackgroundNotificationService,
  BookmarkPlugin,
  MucSubPlugin, RegistrationPlugin
} from '@pazznetwork/ngx-chat';
import { AuthService } from './auth/auth.service';
import {jid} from '@xmpp/client';
import {filter} from 'rxjs/operators';
import {parseJid} from '@pazznetwork/ngx-chat/public-api';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  multiUserChatPlugin: MultiUserChatPlugin;
  mucSubPlugin: MucSubPlugin;
  //bookmarkPlugin: BookmarkPlugin;
  title = 'Angular-UI5';
  isLoggedIn$: Observable<boolean>;

  constructor(private translate: TranslateService,
              private authService: AuthService,
              @Inject(ChatServiceToken) public chatService: ChatService,
              chatBackgroundNotificationService: ChatBackgroundNotificationService) {
    translate.addLangs(['en','pt']);
    translate.setDefaultLang('pt');
    this.multiUserChatPlugin = this.chatService.getPlugin(MultiUserChatPlugin);
    this.mucSubPlugin = chatService.getPlugin(MucSubPlugin);
    chatBackgroundNotificationService.enable();
    //this.bookmarkPlugin = this.chatService.getPlugin(BookmarkPlugin);
    this.chatService.state$
      .pipe(filter(state => state === 'online'))
      .subscribe(async () => {
          const rooms = await this.multiUserChatPlugin.queryAllRooms();
          //const rooms = await this.bookmarkPlugin.retrieveMultiUserChatRooms();
          //console.log(rooms);
          for (const room of rooms) {
            //if (room.autojoin) {
            await this.multiUserChatPlugin.joinRoom(jid(room.jid));
            //}
        }
    });
    this.isLoggedIn$ = this.authService.isLoggedIn;
    this.isLoggedIn$.subscribe( (loggedIn) => {
      setTimeout(async () => {
        const username = localStorage.getItem('username');
        if(loggedIn && username) {
          const register = await this.onRegisterChat(username);
          //chatService.logOut();
          setTimeout(async () => {
            const login = await this.onLoginChat(username);
          },1000);
        } else {
          this.chatService.logOut();
        }
      },1000);
    });
  }

  ngOnInit() {
    this.initTheme();
    if(localStorage.getItem('lang')) {
      this.translate.use(localStorage.getItem('lang'));
      if(localStorage.getItem('lang') == 'pt') {
        setLanguage('pt_PT');
      } else {
        setLanguage(localStorage.getItem('lang'));
      }
    }else {
      this.translate.use('pt');
      setLanguage('pt_PT');
    }
    this.translate.get('general').subscribe((translated: string) => {
      this.chatService.translations.contacts = this.translate.instant('general.contacts');
      this.chatService.translations.placeholder = this.translate.instant('general.insertmessage');
      this.chatService.translations.rooms = this.translate.instant('general.conferences');
      this.chatService.translations.noMessages = this.translate.instant('general.nomessages');
      this.chatService.translations.noContacts = this.translate.instant('general.nocontacts');
      this.chatService.translations.dateFormat = 'EEEE, MM/dd/yyyy';
    });
  }

  initTheme() {
    const theme = localStorage.getItem('theme');
    const html = (<HTMLInputElement>document.documentElement);
    if (theme) {
      setTheme(theme);
      if (theme === 'sap_fiori_3') {
        html.classList.add('theme-white');
      } else {
        html.classList.remove('theme-white');
      }
    } else {
      setTheme('sap_fiori_3');
      html.classList.add('theme-white');
      localStorage.setItem('theme', 'sap_fiori_3');
    }
  }

  async onLoginChat(username) {
    try {
      await this.chatService.logIn({
        domain: environment.chatUrl,
        service: 'wss://'+environment.chatUrl+'/ws/',
        username: username.toLowerCase(),
        password: this.authService.decrypt(environment.chatKey)
      });
      return true;
    } catch (e) {
      //console.log(e);
    }
    return false;
  }

  async onRegisterChat(username) {
    //this.registrationMessage = 'registering ...';
    try {
      await this.chatService.getPlugin(RegistrationPlugin).register(
        username.toLowerCase(),
        this.authService.decrypt(environment.chatKey),
        'wss://'+environment.chatUrl+'/ws/',
        environment.chatUrl
      );
      return true;
      //this.registrationMessage = 'registration successful';
    } catch (e) {
      //console.log(e);
    }
    return false;
  }
}
